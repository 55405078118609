import { Grid } from '@mui/material';
import { useEffect, useMemo } from 'react';
import { Control, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useQualityControlTool } from '@/context/Qualitycontrol.context';
import { checkRequired } from '@/helpers/defect-form';
import { useGetDefects } from '@/hooks/defect-details/use-get-defects';
import { Defect } from '@/types/interfaces/defect-details';
import { FormSelectOption } from '@/types/interfaces/form';
import FormAutocomplete from '../../Common/Form/FormAutocomplete';
import { DynamicFormValues } from '../DefectDetails';

interface SizeFormProps {
  control: Control<DynamicFormValues>;
  selectOptions: Record<keyof Defect, FormSelectOption[]>;
  disabled: boolean;
  isRequired?: boolean;
}

const SizeForm = ({
  control,
  selectOptions,
  disabled,
  isRequired,
}: SizeFormProps) => {
  const { t } = useTranslation();

  const { qcData: { uuid = '' } = {} } = useQualityControlTool();

  const { data: defects } = useGetDefects(uuid);

  const { resetField } = useFormContext<DynamicFormValues>();

  const { field: sizeTypeField } = useController({
    name: 'sizeType',
    control,
  });

  const selectedType = sizeTypeField.value?.value;

  useEffect(() => {
    if (selectedType) {
      resetField('size');
    }
  }, [selectedType]);

  const sizeTypeValueOptions: FormSelectOption[] = useMemo(() => {
    const sizeType = defects?.sizeTypes?.find((s) => s.type === selectedType);

    if (!sizeType || !sizeType?.values?.length) return [];

    return sizeType.values.map((v) => ({
      label: v,
      value: v,
    }));
  }, [selectedType, defects]);

  const required = isRequired ? t('QC_V2.DEFECT.REQUIRED') : undefined;

  const isHidden = !selectOptions.sizeTypes.length;

  if (isHidden) return null;

  return (
    <Grid container spacing={2.5} item>
      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="size_type_combobox"
          control={control}
          name="sizeType"
          label={t('QC_V2.DEFECT.SIZE_TYPE')}
          rules={{
            required: checkRequired(required, selectOptions.sizeTypes),
          }}
          autocompleteProps={{
            options: selectOptions.sizeTypes,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <FormAutocomplete
          data-testid="size_value_combobox"
          control={control}
          name="size"
          label={t('QC_V2.DEFECT.SIZE')}
          rules={{
            required: checkRequired(required, sizeTypeValueOptions),
          }}
          autocompleteProps={{
            options: sizeTypeValueOptions,
            disabled: disabled,
            isOptionEqualToValue: (option, value) =>
              option.value === value.value,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SizeForm;
